import React from 'react';
import { useTranslation } from 'react-i18next';
import { BgImage } from 'gbimage-bridge';

import Content from '@/components/Content';
import Articles from '@/components/Articles';

interface ArticlesWithBackgroundProps {
  data: any;
}

const ArticlesWithBackground: React.FC<ArticlesWithBackgroundProps> = ({
  data,
  bgImage,
}) => {
  const { t } = useTranslation();

  const imageData = bgImage.childImageSharp.gatsbyImageData;

  return (
    <BgImage image={imageData}>
      <div className="bg-black bg-opacity-20 w-full h-full lg:py-20 py-10">
        <Content>
          <Articles articles={data} title={t(`articlesTitle`)} withBgImage />
        </Content>
      </div>
    </BgImage>
  );
};

export default ArticlesWithBackground;
