import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';

import Hero from '@/components/Hero';
import DescriptionSection from '@/components/Home/DescriptionSection';
import AppSection from '@/components/AppPresentation';
import ArticlesWithBackgroundProps from '@/components/Articles/articles-with-background';
import OurPartnersSection from '@/components/Home/OurPartnersSection';

const HomeTemplate = ({ data, pageContext: { articles } }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(`fr`);
  }, []);

  const heroData = [
    {
      bg: data.eggs,
      alt: `Eggs cover`,
      placeholder: `Essayez "oeuf"`,
    },
    {
      bg: data.strawberry,
      alt: `Strawberry cover`,
      placeholder: `Essayez "fraise"`,
    },
    {
      bg: data.peach,
      alt: `Peach cover`,
      placeholder: `Essayez "pêche"`,
    },
    {
      bg: data.orange,
      alt: `Orange cover`,
      placeholder: `Essayez "orange"`,
    },
    {
      bg: data.mais,
      alt: `Mais cover`,
      placeholder: `Essayez "maïs"`,
    },
    {
      bg: data.raspberry,
      alt: `Raspberry cover`,
      placeholder: `Essayez "framboise"`,
    },
    {
      bg: data.figs,
      alt: `Figs cover`,
      placeholder: `Essayez "figues"`,
    },
    {
      bg: data.avocado,
      alt: `Avocado cover`,
      placeholder: `Essayez "avocat"`,
    },
    {
      bg: data.basilic,
      alt: `Basilic cover`,
      placeholder: `Essayez "basilic"`,
    },
    {
      bg: data.lemon,
      alt: `Lemon cover`,
      placeholder: `Essayez "citron"`,
    },
    {
      bg: data.carrot,
      alt: `Carrot cover`,
      placeholder: `Essayez "carottes"`,
    },
    {
      bg: data.raspberryRipes,
      alt: `Raspberry & Ripes cover`,
      placeholder: `Essayez "fruits des bois"`,
    },
  ];

  const elem = heroData[Math.floor(Math.random() * heroData.length)];

  return (
    <Layout title={t(`metaTitle`)} description={t(`metaDescription`)}>
      <Hero source={elem.bg} placeholder={elem.placeholder} alt={elem.alt} />
      <DescriptionSection />
      <AppSection seeMoreLink />

      <ArticlesWithBackgroundProps data={articles} bgImage={data.basilic} />
      <OurPartnersSection />
    </Layout>
  );
};

export default HomeTemplate;

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        ns: { in: ["general", "home", "appPresentation"] }
        language: { eq: "fr" }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    eggs: file(relativePath: { eq: "eggs.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    strawberry: file(relativePath: { eq: "strawberry.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    peach: file(relativePath: { eq: "peach.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    orange: file(relativePath: { eq: "oranges.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    mais: file(relativePath: { eq: "mais.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    raspberry: file(relativePath: { eq: "raspberry.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    figs: file(relativePath: { eq: "figs.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    avocado: file(relativePath: { eq: "avocado.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    basilic: file(relativePath: { eq: "basilic.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    lemon: file(relativePath: { eq: "lemon.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    carrot: file(relativePath: { eq: "carrot.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    raspberryRipes: file(relativePath: { eq: "raspberryRipes.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
